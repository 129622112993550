<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>

    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Partidos',
              quantity: data.counters.games.total,
              icon: 'volleyball-ball',
              change: data.counters.games.actives,
              changeVar: 'partidos activos'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row items-center">
          <!--<div class="my-auto mx-2 w-full md:w-1/6">
            <searchbar
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>-->
          <t-input-group class="mx-2 w-full md:w-1/6">
            <t-select
              v-model="parameters.status"
              placeholder="Estatus"
              :options="[{ id: 1, name: 'Por iniciar' },{ id: 2, name: 'En proceso' },{ id: 3, name: 'Finalizados' }]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2 w-full md:w-1/6">
            <t-select
              v-model="parameters.id_category"
              placeholder="Categoría"
              :options="categories"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2 w-full md:w-1/6">
            <t-select
              v-model="parameters.id_home_team"
              placeholder="Local"
              :options="academies"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2 w-full md:w-1/6">
            <t-select
              v-model="parameters.id_visit_team"
              placeholder="Visitante"
              :options="academies"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2 w-full md:w-1/6">
            <t-select
              v-model="parameters.id_tournament"
              placeholder="Torneo"
              :options="tournaments"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
        </div>
      </div>

      <div class="w-full">
        <basic-table
          ref="list-table"
          module="game"
          :headers="headers"
          :url="'resultados'"
          :data="games"
          :route="'/game'"
          :canChangeStatus="false"
          :tableName="'RESULTADOS'"
          :parameters="parameters"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: [
        "Partido",
        "Torneo",
        "Categoría",
        "Fecha",
        "Resultado",
        "Acciones"
      ],
      data: {
        counters: {
          tournaments: {},
          academies: {},
          players: {},
          coaches: {},
          notices: {},
          games: {},
          categories: {},
          titles: {}
        }
      },
      query: "",
      parameters: {
        active: 1,
        order_key: 'date',
        order_value: 'asc',
        status: 1,
        id_category: null,
        id_home_team: null,
        id_visit_team: null,
        id_tournament: null
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    categories() {
      return this.$store.getters["global/getItems"](
        "category",
        "unpaginated_elements"
      )
    },
    academies() {
      return this.$store.getters["global/getItems"](
        "academy",
        "unpaginated_elements"
      )
    },
    tournaments() {
      return this.$store.getters["global/getItems"](
        "tournament",
        "unpaginated_elements"
      ).filter(el => el.status != 3)
    },
    games() {
      return this.$store.getters["global/getItems"]("game", "elements").map(
        (el) => ({
          id: el.id,
          name: el.home_team.name + " vs " + el.visit_team.name,
          tournament: el.tournament.name,
          category: el.category
            ? `${el.category.name} - ${
                el.subcategory ? el.subcategory.name : ""
              }`
            : "",
          date: el.date,
          result: el.home_goals + " - " + el.visit_goals,
          active: el.active
        })
      )
    }
  },
  methods: {
    changeQuery(value) {
      if (this.$refs["list-table"]) this.$refs["list-table"].changeQuery(value)
    },
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/admin/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getCounters()
    this.getItems("/category/all", "category", true)
    this.getItems("/academy/all", "academy", false)
    this.getItems("/tournament/all", "tournament", false)
  }
}
</script>
